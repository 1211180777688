'use strict';

var ES5ToInteger = require(251);

var ToNumber = require(230);

// https://www.ecma-international.org/ecma-262/6.0/#sec-tointeger

module.exports = function ToInteger(value) {
	var number = ToNumber(value);
	return ES5ToInteger(number);
};
