'use strict';

var GetIntrinsic = require(254);

var $Math = GetIntrinsic('%Math%');

var ToNumber = require(252);
var $isNaN = require(269);
var $isFinite = require(268);
var $sign = require(278);

var $floor = $Math.floor;
var $abs = $Math.abs;

// http://www.ecma-international.org/ecma-262/5.1/#sec-9.4

module.exports = function ToInteger(value) {
	var number = ToNumber(value);
	if ($isNaN(number)) { return 0; }
	if (number === 0 || !$isFinite(number)) { return number; }
	return $sign(number) * $floor($abs(number));
};
