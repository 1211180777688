'use strict';

var msPerDay = require(279).msPerDay;

var DayFromYear = require(156);

// https://ecma-international.org/ecma-262/5.1/#sec-15.9.1.3

module.exports = function TimeFromYear(y) {
	return msPerDay * DayFromYear(y);
};
