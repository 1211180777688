'use strict';

var defineProperties = require(104);
var callBind = require(470);

var implementation = require(467);
var getPolyfill = require(472);
var shim = require(473);

var polyfill = callBind.apply(getPolyfill());
// eslint-disable-next-line no-unused-vars
var bound = function assign(target, source1) {
	return polyfill(Object, arguments);
};

defineProperties(bound, {
	getPolyfill: getPolyfill,
	implementation: implementation,
	shim: shim
});

module.exports = bound;
