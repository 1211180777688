'use strict';

exports.__esModule = true;

var _values = require(8);

var _values2 = _interopRequireDefault(_values);

var _objectWithoutProperties2 = require(14);

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _extends3 = require(12);

var _extends4 = _interopRequireDefault(_extends3);

var _classCallCheck2 = require(11);

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _possibleConstructorReturn2 = require(15);

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require(13);

var _inherits3 = _interopRequireDefault(_inherits2);

var _classnames = require(522);

var _classnames2 = _interopRequireDefault(_classnames);

var _react = require(704);

var _react2 = _interopRequireDefault(_react);

var _elementType = require(671);

var _elementType2 = _interopRequireDefault(_elementType);

var _bootstrapUtils = require(518);

var _StyleConfig = require(516);

var _SafeAnchor = require(513);

var _SafeAnchor2 = _interopRequireDefault(_SafeAnchor);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { 'default': obj }; }

var propTypes = {
  active: _react2['default'].PropTypes.bool,
  disabled: _react2['default'].PropTypes.bool,
  block: _react2['default'].PropTypes.bool,
  onClick: _react2['default'].PropTypes.func,
  componentClass: _elementType2['default'],
  href: _react2['default'].PropTypes.string,
  /**
   * Defines HTML button type attribute
   * @defaultValue 'button'
   */
  type: _react2['default'].PropTypes.oneOf(['button', 'reset', 'submit'])
};

var defaultProps = {
  active: false,
  block: false,
  disabled: false
};

var Button = function (_React$Component) {
  (0, _inherits3['default'])(Button, _React$Component);

  function Button() {
    (0, _classCallCheck3['default'])(this, Button);
    return (0, _possibleConstructorReturn3['default'])(this, _React$Component.apply(this, arguments));
  }

  Button.prototype.renderAnchor = function renderAnchor(elementProps, className) {
    return _react2['default'].createElement(_SafeAnchor2['default'], (0, _extends4['default'])({}, elementProps, {
      className: (0, _classnames2['default'])(className, elementProps.disabled && 'disabled')
    }));
  };

  Button.prototype.renderButton = function renderButton(_ref, className) {
    var componentClass = _ref.componentClass,
        elementProps = (0, _objectWithoutProperties3['default'])(_ref, ['componentClass']);

    var Component = componentClass || 'button';

    return _react2['default'].createElement(Component, (0, _extends4['default'])({}, elementProps, {
      type: elementProps.type || 'button',
      className: className
    }));
  };

  Button.prototype.render = function render() {
    var _extends2;

    var _props = this.props,
        active = _props.active,
        block = _props.block,
        className = _props.className,
        props = (0, _objectWithoutProperties3['default'])(_props, ['active', 'block', 'className']);

    var _splitBsProps = (0, _bootstrapUtils.splitBsProps)(props),
        bsProps = _splitBsProps[0],
        elementProps = _splitBsProps[1];

    var classes = (0, _extends4['default'])({}, (0, _bootstrapUtils.getClassSet)(bsProps), (_extends2 = {
      active: active
    }, _extends2[(0, _bootstrapUtils.prefix)(bsProps, 'block')] = block, _extends2));
    var fullClassName = (0, _classnames2['default'])(className, classes);

    if (elementProps.href) {
      return this.renderAnchor(elementProps, fullClassName);
    }

    return this.renderButton(elementProps, fullClassName);
  };

  return Button;
}(_react2['default'].Component);

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

exports['default'] = (0, _bootstrapUtils.bsClass)('btn', (0, _bootstrapUtils.bsSizes)([_StyleConfig.Size.LARGE, _StyleConfig.Size.SMALL, _StyleConfig.Size.XSMALL], (0, _bootstrapUtils.bsStyles)([].concat((0, _values2['default'])(_StyleConfig.State), [_StyleConfig.Style.DEFAULT, _StyleConfig.Style.PRIMARY, _StyleConfig.Style.LINK]), _StyleConfig.Style.DEFAULT, Button)));
module.exports = exports['default'];