'use strict';

var GetIntrinsic = require(254);

var $test = GetIntrinsic('RegExp.prototype.test');

var callBind = require(259);

module.exports = function regexTester(regex) {
	return callBind($test, regex);
};
