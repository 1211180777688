'use strict';

var Day = require(155);
var DayFromYear = require(156);
var YearFromTime = require(243);

// https://ecma-international.org/ecma-262/5.1/#sec-15.9.1.4

module.exports = function DayWithinYear(t) {
	return Day(t) - DayFromYear(YearFromTime(t));
};
