"use strict";

var _interopRequireDefault = require(2);

exports.__esModule = true;
exports.default = filterEvents;

var _contains = _interopRequireDefault(require(117));

var _querySelectorAll = _interopRequireDefault(require(123));

function filterEvents(selector, handler) {
  return function filterHandler(e) {
    var top = e.currentTarget,
        target = e.target,
        matches = (0, _querySelectorAll.default)(top, selector);
    if (matches.some(function (match) {
      return (0, _contains.default)(match, target);
    })) handler.call(this, e);
  };
}

module.exports = exports["default"];