'use strict';

var $isFinite = require(268);
var msPerDay = require(279).msPerDay;

// https://ecma-international.org/ecma-262/5.1/#sec-15.9.1.13

module.exports = function MakeDate(day, time) {
	if (!$isFinite(day) || !$isFinite(time)) {
		return NaN;
	}
	return (day * msPerDay) + time;
};
