'use strict';

var define = require(104);

var implementation = require(474);
var getPolyfill = require(476);
var shim = require(477);

var polyfill = getPolyfill();

define(polyfill, {
	getPolyfill: getPolyfill,
	implementation: implementation,
	shim: shim
});

module.exports = polyfill;
