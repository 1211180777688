"use strict";

var _interopRequireDefault = require(2);

exports.__esModule = true;
exports.default = void 0;

var _addClass = _interopRequireDefault(require(106));

exports.addClass = _addClass.default;

var _removeClass = _interopRequireDefault(require(109));

exports.removeClass = _removeClass.default;

var _hasClass = _interopRequireDefault(require(107));

exports.hasClass = _hasClass.default;
var _default = {
  addClass: _addClass.default,
  removeClass: _removeClass.default,
  hasClass: _hasClass.default
};
exports.default = _default;