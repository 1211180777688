"use strict";

var _interopRequireDefault = require(2);

exports.__esModule = true;
exports.default = void 0;

var _inDOM = _interopRequireDefault(require(135));

var _on = _interopRequireDefault(require(114));

var _off = _interopRequireDefault(require(113));

var listen = function listen() {};

if (_inDOM.default) {
  listen = function listen(node, eventName, handler, capture) {
    (0, _on.default)(node, eventName, handler, capture);
    return function () {
      (0, _off.default)(node, eventName, handler, capture);
    };
  };
}

var _default = listen;
exports.default = _default;
module.exports = exports["default"];