"use strict";

var _interopRequireDefault = require(2);

exports.__esModule = true;
exports.default = height;

var _offset = _interopRequireDefault(require(120));

var _isWindow = _interopRequireDefault(require(119));

function height(node, client) {
  var win = (0, _isWindow.default)(node);
  return win ? win.innerHeight : client ? node.clientHeight : (0, _offset.default)(node).height;
}

module.exports = exports["default"];