'use strict';

var isPropertyDescriptor = require(272);

var Type = require(240);
var IsDataDescriptor = require(181);
var IsAccessorDescriptor = require(176);

// https://ecma-international.org/ecma-262/6.0/#sec-property-descriptor-specification-type

module.exports = function IsPropertyDescriptor(Desc) {
	return isPropertyDescriptor({
		IsDataDescriptor: IsDataDescriptor,
		IsAccessorDescriptor: IsAccessorDescriptor,
		Type: Type
	}, Desc);
};
