var pIE = require(71);
var createDesc = require(73);
var toIObject = require(82);
var toPrimitive = require(85);
var has = require(44);
var IE8_DOM_DEFINE = require(47);
var gOPD = Object.getOwnPropertyDescriptor;

exports.f = require(37) ? gOPD : function getOwnPropertyDescriptor(O, P) {
  O = toIObject(O);
  P = toPrimitive(P, true);
  if (IE8_DOM_DEFINE) try {
    return gOPD(O, P);
  } catch (e) { /* empty */ }
  if (has(O, P)) return createDesc(!pIE.f.call(O, P), O[P]);
};
