'use strict';

var GetIntrinsic = require(254);

var $EvalError = GetIntrinsic('%EvalError%');

var DaysInYear = require(158);
var YearFromTime = require(243);

// https://ecma-international.org/ecma-262/5.1/#sec-15.9.1.3

module.exports = function InLeapYear(t) {
	var days = DaysInYear(YearFromTime(t));
	if (days === 365) {
		return 0;
	}
	if (days === 366) {
		return 1;
	}
	throw new $EvalError('Assertion failed: there are not 365 or 366 days in a year, got: ' + days);
};
