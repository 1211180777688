'use strict';

var GetIntrinsic = require(254);

var $floor = GetIntrinsic('%Math.floor%');

var mod = require(275);
var timeConstants = require(279);
var msPerHour = timeConstants.msPerHour;
var HoursPerDay = timeConstants.HoursPerDay;

// https://ecma-international.org/ecma-262/5.1/#sec-15.9.1.10

module.exports = function HourFromTime(t) {
	return mod($floor(t / msPerHour), HoursPerDay);
};
