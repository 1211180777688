'use strict';

var GetIntrinsic = require(254);

var $Object = GetIntrinsic('%Object%');

var RequireObjectCoercible = require(208);

// https://www.ecma-international.org/ecma-262/6.0/#sec-toobject

module.exports = function ToObject(value) {
	RequireObjectCoercible(value);
	return $Object(value);
};
