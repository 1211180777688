'use strict';

exports.__esModule = true;

var _extends2 = require(12);

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require(14);

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _classCallCheck2 = require(11);

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _possibleConstructorReturn2 = require(15);

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require(13);

var _inherits3 = _interopRequireDefault(_inherits2);

var _classnames = require(522);

var _classnames2 = _interopRequireDefault(_classnames);

var _react = require(704);

var _react2 = _interopRequireDefault(_react);

var _bootstrapUtils = require(518);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { 'default': obj }; }

var contextTypes = {
  $bs_navbar: _react2['default'].PropTypes.shape({
    bsClass: _react2['default'].PropTypes.string
  })
};

var NavbarHeader = function (_React$Component) {
  (0, _inherits3['default'])(NavbarHeader, _React$Component);

  function NavbarHeader() {
    (0, _classCallCheck3['default'])(this, NavbarHeader);
    return (0, _possibleConstructorReturn3['default'])(this, _React$Component.apply(this, arguments));
  }

  NavbarHeader.prototype.render = function render() {
    var _props = this.props,
        className = _props.className,
        props = (0, _objectWithoutProperties3['default'])(_props, ['className']);

    var navbarProps = this.context.$bs_navbar || { bsClass: 'navbar' };

    var bsClassName = (0, _bootstrapUtils.prefix)(navbarProps, 'header');

    return _react2['default'].createElement('div', (0, _extends3['default'])({}, props, { className: (0, _classnames2['default'])(className, bsClassName) }));
  };

  return NavbarHeader;
}(_react2['default'].Component);

NavbarHeader.contextTypes = contextTypes;

exports['default'] = NavbarHeader;
module.exports = exports['default'];