'use strict';

/* eslint global-require: 0 */
// https://www.ecma-international.org/ecma-262/7.0/#sec-abstract-operations
var ES2016 = {
	'Abstract Equality Comparison': require(139),
	'Abstract Relational Comparison': require(140),
	'Strict Equality Comparison': require(217),
	AdvanceStringIndex: require(141),
	ArrayCreate: require(142),
	ArraySetLength: require(143),
	ArraySpeciesCreate: require(144),
	Call: require(145),
	CanonicalNumericIndexString: require(146),
	CompletePropertyDescriptor: require(147),
	CreateDataProperty: require(148),
	CreateDataPropertyOrThrow: require(149),
	CreateHTML: require(150),
	CreateIterResultObject: require(151),
	CreateListFromArrayLike: require(152),
	CreateMethodProperty: require(153),
	DateFromTime: require(154),
	Day: require(155),
	DayFromYear: require(156),
	DaysInYear: require(158),
	DayWithinYear: require(157),
	DefinePropertyOrThrow: require(159),
	DeletePropertyOrThrow: require(160),
	EnumerableOwnNames: require(161),
	FromPropertyDescriptor: require(162),
	Get: require(163),
	GetIterator: require(164),
	GetMethod: require(165),
	GetOwnPropertyKeys: require(166),
	GetPrototypeFromConstructor: require(167),
	GetSubstitution: require(168),
	GetV: require(169),
	HasOwnProperty: require(170),
	HasProperty: require(171),
	HourFromTime: require(172),
	InLeapYear: require(173),
	InstanceofOperator: require(174),
	Invoke: require(175),
	IsAccessorDescriptor: require(176),
	IsArray: require(177),
	IsCallable: require(178),
	IsConcatSpreadable: require(179),
	IsConstructor: require(180),
	IsDataDescriptor: require(181),
	IsExtensible: require(182),
	IsGenericDescriptor: require(183),
	IsInteger: require(184),
	IsPromise: require(185),
	IsPropertyDescriptor: require(186),
	IsPropertyKey: require(187),
	IsRegExp: require(188),
	IterableToArrayLike: require(189),
	IteratorClose: require(190),
	IteratorComplete: require(191),
	IteratorNext: require(192),
	IteratorStep: require(193),
	IteratorValue: require(194),
	MakeDate: require(195),
	MakeDay: require(196),
	MakeTime: require(197),
	MinFromTime: require(198),
	modulo: require(244),
	MonthFromTime: require(199),
	msFromTime: require(245),
	ObjectCreate: require(200),
	OrdinaryDefineOwnProperty: require(201),
	OrdinaryGetOwnProperty: require(202),
	OrdinaryGetPrototypeOf: require(203),
	OrdinarySetPrototypeOf: require(206),
	OrdinaryHasInstance: require(204),
	OrdinaryHasProperty: require(205),
	RegExpExec: require(207),
	RequireObjectCoercible: require(208),
	SameValue: require(209),
	SameValueNonNumber: require(210),
	SameValueZero: require(211),
	SecFromTime: require(212),
	Set: require(213),
	SetFunctionName: require(214),
	SetIntegrityLevel: require(215),
	SpeciesConstructor: require(216),
	SymbolDescriptiveString: require(218),
	TestIntegrityLevel: require(219),
	thisBooleanValue: require(246),
	thisNumberValue: require(247),
	thisStringValue: require(248),
	thisTimeValue: require(249),
	TimeClip: require(220),
	TimeFromYear: require(221),
	TimeWithinDay: require(222),
	ToBoolean: require(223),
	ToDateString: require(224),
	ToInt16: require(225),
	ToInt32: require(226),
	ToInt8: require(227),
	ToInteger: require(228),
	ToLength: require(229),
	ToNumber: require(230),
	ToObject: require(231),
	ToPrimitive: require(232),
	ToPropertyDescriptor: require(233),
	ToPropertyKey: require(234),
	ToString: require(235),
	ToUint16: require(236),
	ToUint32: require(237),
	ToUint8: require(238),
	ToUint8Clamp: require(239),
	Type: require(240),
	ValidateAndApplyPropertyDescriptor: require(241),
	WeekDay: require(242),
	YearFromTime: require(243)
};

module.exports = ES2016;
