'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (componentOrElement) {
  return (0, _ownerWindow2.default)(_reactDom2.default.findDOMNode(componentOrElement));
};

var _reactDom = require(523);

var _reactDom2 = _interopRequireDefault(_reactDom);

var _ownerWindow = require(116);

var _ownerWindow2 = _interopRequireDefault(_ownerWindow);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

module.exports = exports['default'];