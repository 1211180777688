'use strict';

var GetIntrinsic = require(254);

var $TypeError = GetIntrinsic('%TypeError%');

var getIteratorMethod = require(264);
var AdvanceStringIndex = require(141);
var Call = require(145);
var GetMethod = require(165);
var IsArray = require(177);
var Type = require(240);

// https://ecma-international.org/ecma-262/6.0/#sec-getiterator

module.exports = function GetIterator(obj, method) {
	var actualMethod = method;
	if (arguments.length < 2) {
		actualMethod = getIteratorMethod(
			{
				AdvanceStringIndex: AdvanceStringIndex,
				GetMethod: GetMethod,
				IsArray: IsArray,
				Type: Type
			},
			obj
		);
	}
	var iterator = Call(actualMethod, obj);
	if (Type(iterator) !== 'Object') {
		throw new $TypeError('iterator must return an object');
	}

	return iterator;
};
