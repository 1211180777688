"use strict";

var _interopRequireDefault = require(2);

exports.__esModule = true;
exports.default = addClass;

var _hasClass = _interopRequireDefault(require(107));

function addClass(element, className) {
  if (element.classList) element.classList.add(className);else if (!(0, _hasClass.default)(element, className)) if (typeof element.className === 'string') element.className = element.className + ' ' + className;else element.setAttribute('class', (element.className && element.className.baseVal || '') + ' ' + className);
}

module.exports = exports["default"];