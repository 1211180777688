'use strict';

var GetIntrinsic = require(254);

var $Math = GetIntrinsic('%Math%');

var ToNumber = require(230);

var $isNaN = require(269);
var $isFinite = require(268);
var $sign = require(278);
var $mod = require(275);

var $floor = $Math.floor;
var $abs = $Math.abs;

module.exports = function ToUint8(argument) {
	var number = ToNumber(argument);
	if ($isNaN(number) || number === 0 || !$isFinite(number)) { return 0; }
	var posInt = $sign(number) * $floor($abs(number));
	return $mod(posInt, 0x100);
};
