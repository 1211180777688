"use strict";

var _interopRequireDefault = require(2);

exports.__esModule = true;
exports.default = style;

var _camelizeStyle = _interopRequireDefault(require(132));

var _hyphenateStyle = _interopRequireDefault(require(134));

var _getComputedStyle2 = _interopRequireDefault(require(126));

var _removeStyle = _interopRequireDefault(require(128));

var _properties = require(130);

var _isTransform = _interopRequireDefault(require(129));

function style(node, property, value) {
  var css = '';
  var transforms = '';
  var props = property;

  if (typeof property === 'string') {
    if (value === undefined) {
      return node.style[(0, _camelizeStyle.default)(property)] || (0, _getComputedStyle2.default)(node).getPropertyValue((0, _hyphenateStyle.default)(property));
    } else {
      (props = {})[property] = value;
    }
  }

  Object.keys(props).forEach(function (key) {
    var value = props[key];

    if (!value && value !== 0) {
      (0, _removeStyle.default)(node, (0, _hyphenateStyle.default)(key));
    } else if ((0, _isTransform.default)(key)) {
      transforms += key + "(" + value + ") ";
    } else {
      css += (0, _hyphenateStyle.default)(key) + ": " + value + ";";
    }
  });

  if (transforms) {
    css += _properties.transform + ": " + transforms + ";";
  }

  node.style.cssText += ';' + css;
}

module.exports = exports["default"];